module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"guldmaerker","accessToken":"MC5YeDhyUGhFQUFDWUFxLXJa.ZmAn77-977-977-977-977-9Z--_vSjvv73vv73vv73vv70EACBl77-977-977-977-9Slc777-977-977-977-977-977-9","omitPrismicScript":true,"defaultLang":"da-dk","langs":["da-dk"],"path":"/preview","previews":true,"shortenUrlLangs":true,"pages":[{"type":"Page","match":"/:lang?/:uid?","component":"/opt/build/repo/src/templates/page.jsx","langs":["da-dk"]},{"type":"Article","match":"/:lang?/:uid?","component":"/opt/build/repo/src/templates/article.jsx","langs":["da-dk"]}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2105469072998722"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Guldmærker","short_name":"guldmaerker","start_url":"/","background_color":"#ffffff","theme_color":"#ee3338","display":"standalone","icon":"src/images/square-icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bb3398b961a699d12c8e6c07dd747c50"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
